import React, { Component } from 'react';
import { Tabs } from 'antd';
import EngilshPolicy from '../../components/policy/english';
import GermanPolicy from '../../components/policy/german';
import * as styles from './Index.module.scss';

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // const items = [
    //   {
    //     label: 'English',
    //     key: 'English',
    //     children: <EngilshPolicy />,
    //   },
    //   {
    //     label: 'German',
    //     key: 'German',
    //     children: <GermanPolicy />,
    //   },
    // ]

    return (
      <div className={styles.page}>
        {/* <Tabs defaultActiveKey="English" items={items} /> */}
        <Tabs tabBarStyle={{ marginBottom: 0 }}>
          <Tabs.TabPane tab="RECRUITMENT INFORMATION NOTICE" key="English">
            <EngilshPolicy />
          </Tabs.TabPane>
          <Tabs.TabPane tab="ERKLÄRUNG ZU REKRUTIERUNGSDATEN" key="German">
            <GermanPolicy />
          </Tabs.TabPane>
        </Tabs>
      </div>

    );
  }
}

export default PrivacyPolicy;
