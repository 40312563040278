import React from 'react';
import { Anchor, Row, Col, Divider, Table, Tooltip } from 'antd';
import * as styles from './policy.module.scss';
const { Link } = Anchor;

const GermanPolicy = () => {
    const dataSource = [
        {
            key: '1',
            EmploymentEntity: 'Anker Innovations Technology Co.,Ltd',
            HRContactInformation: 'hr-team@anker-in.com',
            CompanyContactInformation: 'Room701, Bldg 7, Zhongdian Software Park, 39 Jianshan Road, Hi-tech Zone, Changsha City, Hunan Province, China',
        },
        {
            key: '2',
            EmploymentEntity: 'Fantasia  Trading LLC',
            HRContactInformation: 'hr-na@anker.com',
            CompanyContactInformation: '5350 Ontario Mills Pkwy, Suite 100, Ontario, CA 91764',
        },
        {
            key: '3',
            EmploymentEntity: 'POWER  MOBILE LIFE LLC',
            HRContactInformation: 'hr-na@anker.com',
            CompanyContactInformation: '10900 NE 8th St, Ste 501, Bellevue WA 98004',
        },
        {
            key: '4',
            EmploymentEntity: 'Anker Innovations Deutschland GmbH; ',
            HRContactInformation: 'hr-team@anker-in.com',
            CompanyContactInformation: 'Georg-Muche-Street 3，80807 Munich',
        },
        {
            key: '5',
            EmploymentEntity: 'Anker Technology (UK) Ltd. ',
            HRContactInformation: 'hr-team@anker-in.com',
            CompanyContactInformation: 'Gnr8 49 Clarendon Road, Watford, Hertfordshire, England, WD17 1HP',
        },
    ];

    const columns = [
        {
            title: 'Beschäftigungseinrichtung',
            dataIndex: 'EmploymentEntity',
            key: 'EmploymentEntity',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 150,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                }
            },
            render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
        },
        {
            title: 'HR-Kontaktinformationen',
            dataIndex: 'HRContactInformation',
            key: 'HRContactInformation',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 150,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                }
            },
            render: (text) => <Tooltip placement="topLeft" title={text}><a>{text}</a></Tooltip>
        },
        {
            title: 'Unternehmenskontaktinformationen',
            dataIndex: 'CompanyContactInformation',
            key: 'CompanyContactInformation',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 150,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                }
            },
            render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
        },
    ];
    return (
        <div className={styles.home}>
            <Row>
                <Col span={6}>
                    <Anchor style={{ paddingTop: '10px', }}>
                        {/* <Link href='#policyGe0' title='ERKLÄRUNG ZU REKRUTIERUNGSDATEN' /> */}
                        <Link className={styles.anchorLink} href='#policyGe1' title='1. Welche personenbezogenen Daten erheben wir?' />
                        <Link className={styles.anchorLink} href='#policyGe2' title='2. Wie wir Ihre personenbezogenen Daten verwenden' />
                        <Link className={styles.anchorLink} href='#policyGe3' title='3. Wie wir Ihre personenbezogenen Daten weitergeben' />
                        <Link className={styles.anchorLink} href='#policyGe4' title='4. Für welche Zwecke und auf welcher Grundlage verarbeiten wir Ihre personenbezogenen Daten?' />
                        <Link className={styles.anchorLink} href='#policyGe5' title='5. Internationale Übermittlung Ihrer personenbezogenen Daten' />
                        <Link className={styles.anchorLink} href='#policyGe6' title='6. Wie werden Ihre personenbezogenen Daten gesichert?' />
                        <Link className={styles.anchorLink} href='#policyGe7' title='7. Wie lange werden Ihre personenbezogenen Daten aufbewahrt?' />
                        <Link className={styles.anchorLink} href='#policyGe8' title='8. Wie können Sie Ihre Datenschutzrechte ausüben?' />
                        <Link className={styles.anchorLink} href='#policyGe9' title='9. Änderungen an dieser Erklärung' />
                        <Link className={styles.anchorLink} href='#policyGe10' title='10. Wie können Sie uns kontaktieren oder eine Beschwerde einreichen?' />
                    </Anchor>
                </Col>
                <Col span={18} style={{ padding: '0 20px', }}>
                    <div id="policyGe0" className={styles.policyTitle}>ERKLÄRUNG ZU REKRUTIERUNGSDATEN</div>
                    <div className="policy-describe">
                        <p>Aktualisiert am Juni 20, 2023</p>
                        <p>Vielen Dank, dass Sie sich für ein Beschäftigungsverhältnis bei ANKER und seinen Tochtergesellschaften, Joint Ventures und verbundenen Unternehmen („<span className={styles.fontMax}>wir</span>“ oder „<span className={styles.fontMax}>Unternehmen</span>“) interessieren. Ihre Privatsphäre ist für uns von größter Bedeutung und wir verpflichten uns, Ihre Privatsphäre zu schützen.</p>
                        <p>Diese Erklärung zu Rekrutierungsdaten („<span className={styles.fontMax}>Erklärung</span>“) ist eine globale Erklärung, in der allgemein beschrieben wird, wie wir über unser Online-Rekrutierungssystem auf einer Website auf unserer Domain, deren genauer Link sich von Zeit zu Zeit ändern kann (die „<span className={styles.fontMax}>Site</span>“), und durch andere Mittel Daten über Kandidaten erheben und wie wir diese Daten verarbeiten und möglicherweise verwenden. Das Unternehmen erkennt an, dass die Gesetze bestimmter Gerichtsbarkeiten das Unternehmen ganz oder teilweise daran hindern können, solche Daten in der unten beschriebenen Weise zu erheben, zu verarbeiten oder zu verwenden. Sollte ein Teil dieser Erklärung im Widerspruch zu geltendem Bundes-, Landes-, Kommunal- oder sonstigem Recht stehen, hat dieses Recht Vorrang und ist maßgeblich. Ebenso wird das Unternehmen solche Daten nicht in einer Weise erheben, verarbeiten oder verwenden, die gegen geltendes Bundes-, Landes-, Kommunal- oder sonstiges Recht verstößt. </p>
                        <p>Zweck dieser Erklärung ist es, Sie darüber zu informieren, wie das Unternehmen personenbezogene Daten verarbeitet, die wir über die Website und andere Mittel über Kandidaten erhalten. Einzelheiten zum Datenschutzbeauftragten der personenbezogenen Daten finden Sie in Anhang 1, wenn Sie im Europäischen Wirtschaftsraum, im Vereinigten Königreich und in den Vereinigten Staaten ansässig sind oder der Arbeitsort der von uns auf der Website veröffentlichten Stellenanzeigen im Europäischen Wirtschaftsraum, im Vereinigten Königreich und in den Vereinigten Staaten liegt.</p>
                        <p>„<span className={styles.fontMax}>Kandidat</span>“ bezeichnet jede Person, die sich (direkt oder indirekt über einen Vertreter) über die Website um eine Stelle im Unternehmen bewirbt.</p>
                        <p>„<span className={styles.fontMax}>Verarbeitung</span>“ bezeichnet jeden Vorgang oder jede Reihe von Vorgängen, die mit personenbezogenen Daten oder einer Reihe von personenbezogenen Daten durchgeführt werden, unabhängig davon, ob dies mit automatisierten Mitteln geschieht oder nicht, wie z.B. Erheben, Aufzeichnen, Organisieren, Strukturieren, Speichern, Anpassen oder Ändern, Abrufen, Abfragen, Verwenden, Offenlegen durch Übermittlung, Verbreiten oder anderweitiges Verfügbarmachen, Abgleichen oder Kombinieren, Einschränken, Löschen oder Vernichten.</p>
                        <p>„<span className={styles.fontMax}>Personenbezogene Daten</span>“ sind alle Daten, die sich auf eine identifizierte oder identifizierbare natürliche Person (in dieser Erklärung ‘betroffene Person’, d.h. unsere Kandidaten) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt identifiziert werden kann, insbesondere durch Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer oder zu Beschäftigungsdaten.</p>
                        <p>Wir werden unser Bestes tun, um Ihnen alle Daten in einem klaren und lesbaren Format zur Verfügung zu stellen. Sollten Sie jedoch nach der Lektüre dieser Erklärung noch Fragen zu unserer Verwendung Ihrer personenbezogenen Daten haben, wenden Sie sich bitte an unsere lokale Personalabteilung.</p>
                    </div>
                    <div id="policyGe1" className={styles.policyTips}>
                        <div className={styles.title}>1. Welche personenbezogenen Daten erheben wir?</div>
                        <p>Das Unternehmen verarbeitet personenbezogene Daten von Kandidaten, soweit dies erforderlich ist, um die Bewerbung eines Kandidaten um eine Beschäftigung zu bewerten und um unsere berechtigten Geschäftsinteressen im Zusammenhang mit der Bearbeitung und Prüfung der Bewerbung eines Kandidaten um einen Arbeitsplatz im Unternehmen zu wahren.</p>
                        <p className={styles.subTitle}>1.1 Zu den Kategorien personenbezogener Daten, die das Unternehmen über die Website und auf anderem Wege erhebt, gehören unter anderem die folgenden:</p>
                        <p>- Kontaktinformationen: Ihr Name, Ihre E-Mail-Adresse, Ihre Telefonnummer, Ihre Wohnanschrift</p>
                        <p>- Personenbezogene Daten: Geschlecht, Geburtsdatum, Nationalität und Einwanderungsstatus</p>
                        <p>- Daten zur Beschäftigung und Ausbildung: frühere Beschäftigungen und Stellen, Qualifikationen, Besuch von Bildungseinrichtungen und absolvierte Schulungen,Frühere Einkünfte (Gehaltsabrechnungen) und Nachweise über frühere Arbeitsleistungen</p>
                        <p>- Daten zur Hintergrundprüfung,einschließlich der Angabe von Kontaktdaten früherer Kollegen</p>
                        <p>- Angaben zum Lebenslauf, Anschreiben oder Arbeitsproben</p>
                        <p>- Beurteilungen der Kompetenzen</p>
                        <p className={styles.subTitle}>1.2 Von Drittanbietern</p>
                        <p>Wir können im Zusammenhang mit Ihrer Bewerbung und im Rahmen des Vorstellungsgesprächs Daten über Sie aus anderen Quellen einholen, soweit dies gesetzlich zulässig ist.</p>
                        <p>Es kann sein, dass wir durch Ihren Kontakt mit uns Daten von Drittanbietern erhalten, wie z.B. von Empfehlungsgebern, Anbietern von Identitätsüberprüfungen und Dienstleistern für Hintergrundüberprüfungen. Sie können uns auch Zugang zu bestimmten personenbezogenen Daten gewähren, die von Drittanbietern auf professionellen Social-Media-Seiten wie LinkedIn oder Glassdoor gespeichert sind.</p>
                        <p className={styles.subTitle}>1.3 Besondere Kategorien personenbezogener Daten, die erhoben werden</p>
                        <p>Im Allgemeinen fordern wir von Kandidaten keine besonderen Kategorien personenbezogener Daten, wie z.B. Daten über Ihre Gesundheit, Rasse, ethnische Zugehörigkeit, Religion oder sexuelle Orientierung oder Daten über Straftaten, strafrechtliche Verurteilungen oder Sicherheitsmaßnahmen oder andere Daten, die nach den geltenden lokalen Gesetzen als solche gekennzeichnet sind.</p>
                        <p>Das Unternehmen erhebt besondere Kategorien personenbezogener Daten nur, wenn dies gesetzlich zulässig ist und nur nach Schaffung angemessener Sicherheitsvorkehrungen. Die Verarbeitung der folgenden besonderen Kategorien wird vom Unternehmen nur vorgenommen, wenn dies gesetzlich erlaubt ist und unter den folgenden Umständen:</p>
                        <p>Strafrechtliche Verurteilungen - nur dann, wenn Daten über frühere strafrechtliche Verurteilungen eines Kandidaten unmittelbar relevant sind, um seine Eignung für eine bestimmte Stelle festzustellen, wenn sie für potenzielle oder laufende Rechtsstreitigkeiten oder Gerichtsverfahren relevant sind, die sich auf das künftige Beschäftigungsverhältnis des Kandidaten mit dem Unternehmen auswirken könnten, oder wenn sie erforderlich sind, um die Verpflichtungen des Unternehmens im Rahmen von Gesundheits- und Sicherheitsvorschriften oder anderen geltenden Gesetzen zu erfüllen. Wenn das Unternehmen beabsichtigt, Daten über frühere Straftaten eines Kandidaten einzuholen, wird der Kandidat vorher darüber informiert;</p>
                        <p>Gesundheitliche Daten oder Drogenscreenings - zur Einhaltung von Gesetzen und Vorschriften zur Gesundheit und Sicherheit am Arbeitsplatz oder wenn dies erforderlich ist, um Urlaub oder Abwesenheiten zu verwalten und zu verwalten oder um angemessene Anpassungen vorzunehmen oder zu erwägen.</p>
                        <p>In einigen Gerichtsbarkeiten kann für das Erheben und Verarbeiten besonderer Kategorien personenbezogener Daten eine ausdrückliche Zustimmung verlangt werden. In diesem Fall werden besondere Kategorien personenbezogener Daten vom Unternehmen nur dann verarbeitet, wenn Sie Ihre Zustimmung erteilen und die Verarbeitung besonderer Kategorien personenbezogener Daten durch die Besonderheiten einer Stelle genau gerechtfertigt ist.</p>
                    </div>
                    <div id="policyGe2" className={styles.policyTips}>
                        <div className={styles.title}>2. Wie wir Ihre personenbezogenen Daten verwenden</div>
                        <p>Wir können Ihre personenbezogenen Daten im Rahmen unseres Rekrutierungsverfahrens und der Personalverwaltung auf globaler Ebene verwenden, einschließlich:</p>
                        <p>- Durchführung und Unterstützung von Personalfunktionen und -aktivitäten, einschließlich der Bewertung Ihrer Qualifikationen, Fähigkeiten und Interessen im Hinblick auf unsere Karrieremöglichkeiten;</p>
                        <p>- Erleichterung des Rekrutierungs- und Interviewprozesses, einschließlich der Kommunikation mit Ihnen über Ihre Bewerbung</p>
                        <p>- Vorbereitung eines Angebotsschreibens, wenn Ihre Bewerbung erfolgreich ist;</p>
                        <p>- Überprüfung der von Ihnen oder anderen zur Verfügung gestellten Daten und Durchführung von Referenzüberprüfungen oder Hintergrund- und anderen Überprüfungen vor der Beschäftigung, soweit dies nach geltendem Recht zulässig ist;</p>
                        <p>- Erfüllung geltender Gesetze, Vorschriften, rechtlicher Verfahren oder vollstreckbarer behördlicher Aufforderungen.</p>
                        <p>Wenn Ihnen eine Beschäftigung bei uns angeboten wird und Sie diese annehmen, werden die während des Bewerbungs- und Rekrutierungsverfahrens erhobenen Daten Teil Ihrer Personalakte.</p>
                        <p>Indem Sie Ihre Bewerbung einreichen, erklären Sie sich damit einverstanden, in unsere Talentdatenbank aufgenommen zu werden. Wir können Ihre Bewerbung und Ihre personenbezogenen Daten für zukünftige Beschäftigungsverhältnisse, die Ihren Fähigkeiten und Qualifikationen entsprechen, für 5 Jahre ab dem Datum Ihrer Bewerbung aufbewahren. Sie haben das Recht, Ihre Zustimmung jederzeit zu widerrufen, indem Sie sich an unsere Personalabteilung unter [ hr-recruit@anker-in.com] wenden.</p>
                    </div>
                    <div id="policyGe3" className={styles.policyTips}>
                        <div className={styles.title}>3. Wie wir Ihre personenbezogenen Daten weitergeben</div>
                        <p>- Die personenbezogenen Daten, die wir über die Website und auf anderem Wege erhalten haben, können innerhalb des Unternehmens und unserer Tochtergesellschaften und verbundenen Unternehmen weitergegeben werden, sofern dies für legitime Geschäftszwecke im Zusammenhang mit der Rekrutierung des Kandidaten oder zur Einhaltung geltender gesetzlicher Vorschriften erforderlich ist.</p>
                        <p>- Das Unternehmen kann personenbezogene Daten auch an Drittanbieter (z.B. Anbieter von Hintergrundüberprüfungen) weitergeben, die es mit der Erbringung von Diensten in seinem Namen oder für gesetzliche/regulatorische Zwecke beauftragt hat. Wenn personenbezogene Daten an Drittanbieter weitergegeben werden, werden angemessene Sicherheitsvorkehrungen getroffen, um sicherzustellen, dass die Daten rechtmäßig verarbeitet werden und angemessen geschützt sind.</p>
                        <p>- Einhaltung von Gesetzen oder einer zuständigen Strafverfolgungsbehörde, Aufsichtsbehörde, Regierungsbehörde, einem Gericht oder Drittanbieter: Wir können Daten offenlegen, wenn wir der Meinung sind, dass die Offenlegung aufgrund von Gesetzen oder Vorschriften notwendig oder gefordert ist, um rechtlichen Verfahren oder behördlichen Aufforderungen nachzukommen (einschließlich in Reaktion auf Behörden zur Einhaltung von Gesetzen zur Chancengleichheit, zur nationalen Sicherheit oder zur Strafverfolgung); oder um unsere gesetzlichen Rechte auszuüben, zu begründen oder zu verteidigen.</p>
                        <p>- Wir können Ihre Daten in Verbindung mit oder während der Verhandlungen über eine Fusion, einen Verkauf von Unternehmensvermögen, eine Finanzierung, eine Übernahme, eine Auflösung, eine Unternehmensumstrukturierung oder ein ähnliches Ereignis weitergeben oder übertragen. Wir werden jeden Käufer darüber informieren, dass Ihre Daten nur in Übereinstimmung mit dieser Erklärung verwendet werden dürfen.</p>
                        <p>- Jede andere Person mit Ihrer Zustimmung zur Offenlegung.</p>
                    </div>
                    <div id="policyGe4" className={styles.policyTips}>
                        <div className={styles.title}>4. Für welche Zwecke und auf welcher Grundlage verarbeiten wir Ihre personenbezogenen Daten?</div>
                        <p>Unsere Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten auf die in dieser Erklärung beschriebene Art und Weise sind unter anderem:</p>
                        <p>- Soweit dies für die Vorbereitung und den Abschluss eines Beschäftigungsvertrags mit Ihnen erforderlich ist;</p>
                        <p>- In Übereinstimmung mit bestimmten Einwilligungen, die Sie jederzeit widerrufen können;</p>
                        <p>- Wenn dies zur Erfüllung unserer gesetzlichen Verpflichtungen erforderlich ist;</p>
                        <p>- Um Ihre lebenswichtigen Interessen oder die Interessen anderer zu schützen;</p>
                        <p>- Soweit dies für unsere (oder andere) berechtigten Interessen erforderlich ist, einschließlich unserer Interessen an der Prüfung von Kandidaten für aktuelle und künftige Beschäftigungen und an der Verwaltung und Verbesserung unserer Rekrutierungs und Einstellungsverfahren, es sei denn, diese Interessen werden durch Ihre Interessen oder Grundrechte und -freiheiten, die den Schutz personenbezogener Daten erfordern, überlagert.</p>
                    </div>
                    <div id="policyGe5" className={styles.policyTips}>
                        <div className={styles.title}>5. Internationale Übermittlung Ihrer personenbezogenen Daten</div>
                        <p>Als globales Unternehmen können Daten, die wir erheben, innerhalb unserer weltweiten Organisation international übertragen werden. Indem Sie personenbezogene Daten für einen der Rekrutierungszwecke zur Verfügung stellen, erkennen Sie an, dass Ihre personenbezogenen Daten nach China übermittelt oder dort gespeichert werden können.</p>
                        <p>Wenn Sie Ihren Wohnsitz im Europäischen Wirtschaftsraum (EEA) oder im Vereinigten Königreich haben, schützen wir Ihre personenbezogenen Daten, wenn sie außerhalb des EEA oder des Vereinigten Königreichs übermittelt werden, indem wir:</p>
                        <p>- Sie in einem Gebiet verarbeiten, das nach Auffassung der Europäischen Kommission ein angemessenes Schutzniveau für personenbezogene Daten bietet; oder </p>
                        <p>- Anderweitig angemessene Sicherheitsvorkehrungen zum Schutz Ihrer personenbezogenen Daten treffen, einschließlich der Umsetzung der SCCs der Europäischen Kommission oder der SCCs des Vereinigten Königreichs.</p>
                        <p>Wenn Sie weitere Informationen über die internationale Übermittlung personenbezogener Daten von uns verlangen, wenden Sie sich bitte an uns, indem Sie die Kontaktdaten am Ende dieser Erklärung verwenden.</p>
                    </div>
                    <div id="policyGe6" className={styles.policyTips}>
                        <div className={styles.title}>6. Wie werden Ihre personenbezogenen Daten gesichert? </div>
                        <p>Wir haben angemessene Sicherheitsvorkehrungen getroffen, um die Vertraulichkeit und Sicherheit Ihrer personenbezogenen Daten zu gewährleisten. Wir haben angemessene technische, physische und organisatorische Maßnahmen ergriffen, um personenbezogene Daten vor versehentlicher oder unrechtmäßiger Zerstörung oder versehentlichem Verlust, Beschädigung, Änderung, unbefugter Weitergabe oder Zugriff sowie vor allen anderen Formen der unrechtmäßigen Verarbeitung (einschließlich, aber nicht beschränkt auf unnötiges Erheben) oder Weiterverarbeitung zu schützen.</p>
                    </div>
                    <div id="policyGe7" className={styles.policyTips}>
                        <div className={styles.title}>7. Wie lange werden Ihre personenbezogenen Daten aufbewahrt?</div>
                        <p>Wir werden Ihre personenbezogenen Daten so lange aufbewahren, wie es für die Erfüllung unserer rechtlichen Verpflichtungen, die Beilegung von Streitigkeiten und die Durchsetzung unserer vertraglichen Vereinbarungen erforderlich ist oder wie es für unsere berechtigten Interessen notwendig ist, z. B. um Sie für andere aktuelle und zukünftige Beschäftigungsmöglichkeiten im Unternehmen zu berücksichtigen. </p>
                        <p>Wenn Ihre Bewerbung für eine Stelle im Unternehmen erfolgreich ist, bewahren wir die Daten, die Sie während des Bewerbungsprozesses angeben, sowie die Daten über Ihren Bewerbungsprozess als Teil Ihrer Mitarbeiterunterlagen auf.</p>
                        <p>Wenn Sie nicht möchten, dass wir Ihre Daten zur Berücksichtigung für andere Stellen aufbewahren, oder wenn Sie möchten, dass wir sie aktualisieren, kontaktieren Sie uns bitte über die Kontaktdaten am Ende dieser Erklärung. Bitte beachten Sie jedoch, dass wir einige Daten aufbewahren können, wenn wir gesetzlich dazu aufgefordert werden oder wenn wir uns vor rechtlichen Forderungen schützen müssen.</p>
                    </div>
                    <div id="policyGe8" className={styles.policyTips}>
                        <div className={styles.title}>8. Wie können Sie Ihre Datenschutzrechte ausüben?</div>
                        <p>Wenn Sie Ihre Rechte gemäß den geltenden lokalen Gesetzen in Bezug auf die Verarbeitung Ihrer personenbezogenen Daten über die Website und andere Mittel ausüben möchten, müssen Sie dies schriftlich tun und die Anfrage an unseren lokalen HR-Kontakt richten. Wir können Sie um zusätzliche Daten bitten, um Ihre Identität zu bestätigen und aus Sicherheitsgründen, bevor wir die angeforderten Daten an Sie weitergeben.</p>
                        <p>Sie haben das Recht, Zugang zu Ihren personenbezogenen Daten zu verlangen, sie berichtigen oder löschen zu lassen, ihrer Verarbeitung zu widersprechen oder den Zugang zu ihnen einzuschränken. Sie können auch das Recht haben, eine Kopie Ihrer personenbezogenen Daten in einem maschinenlesbaren Format zu erhalten, sowie das Recht, keiner Entscheidung unterworfen zu werden, die Sie erheblich beeinträchtigt und ausschließlich durch automatisierte Verarbeitung, einschließlich Profiling, getroffen wird. Welche Rechte Ihnen zustehen, hängt von der Gerichtsbarkeit ab, in der Ihr Arbeitgeber ansässig ist.</p>
                        <p>Wenn Sie Ihre Zustimmung zu einem bestimmten Zweck gegeben haben, können Sie Ihre Zustimmung jederzeit widerrufen. Bitte beachten Sie, dass der Widerruf nicht rückwirkend gilt.</p>
                    </div>
                    <div id="policyGe9" className={styles.policyTips}>
                        <div className={styles.title}>9. Änderungen an dieser Erklärung</div>
                        <p>Wir werden diese Erklärung in regelmäßigen Abständen überprüfen und aktualisieren, um sie an veränderte rechtliche, technische und geschäftliche Entwicklungen anzupassen. Wenn wir diese Erklärung aktualisieren, werden wir das Datum der letzten Überarbeitung oben vermerken. Wenn wir wesentliche Änderungen an diesem Hinweis vornehmen, können wir geeignete Maßnahmen ergreifen, um Sie in einer Weise zu informieren, die der Bedeutung der von uns vorgenommenen Änderungen entspricht. </p>
                    </div>
                    <div id="policyGe10" className={styles.policyTips}>
                        <div className={styles.title}>10. Wie können Sie uns kontaktieren oder eine Beschwerde einreichen?</div>
                        <p>Wenn Sie Fragen dazu haben, wie wir Ihre personenbezogenen Daten verarbeiten, lesen Sie bitte zunächst diese Erklärung. Bei weiteren Fragen wenden Sie sich bitte an unsere lokale Personalabteilung. Wir nehmen Ihre Zufriedenheit sehr ernst. Sollten Sie eine Beschwerde haben, richten Sie diese bitte ebenfalls an die oben genannte E-Mail-Adresse und wir werden Ihnen so schnell wie möglich antworten. </p>
                        <p>Wenn wir Ihre Bedenken nicht ausräumen können, können Sie natürlich auch eine Beschwerde bei Ihrer örtlichen Datenschutzaufsichtsbehörde einreichen.</p>
                    </div>
                    <Divider />
                    <div className={styles.tableTitle}>Anhang 1</div>
                    <p>Die folgenden Unternehmen sind für die Verarbeitung personenbezogener Daten innerhalb des Europäischen Wirtschaftsraums, Großbritanniens oder der Vereinigten Staaten verantwortlich:</p>
                    <Table rowClassName={styles.tableRow} className={styles.table} bordered dataSource={dataSource} columns={columns} pagination={false} />
                </Col>
            </Row>
        </div>
    );
};

export default GermanPolicy;
