import React from 'react';
import { Anchor, Row, Col, Divider, Table, Tooltip } from 'antd';
import * as styles from './policy.module.scss';
const { Link } = Anchor;

const EnglishPolicy = () => {
    const dataSource = [
        {
            key: '1',
            EmploymentEntity: 'Anker Innovations Technology Co.,Ltd',
            HRContactInformation: 'hr-team@anker-in.com',
            CompanyContactInformation: 'Room701, Bldg 7, Zhongdian Software Park, 39 Jianshan Road, Hi-tech Zone, Changsha City, Hunan Province, China',
        },
        {
            key: '2',
            EmploymentEntity: 'Fantasia  Trading LLC',
            HRContactInformation: 'hr-na@anker.com',
            CompanyContactInformation: '5350 Ontario Mills Pkwy, Suite 100, Ontario, CA 91764 ',
        },
        {
            key: '3',
            EmploymentEntity: 'POWER  MOBILE LIFE LLC',
            HRContactInformation: 'hr-na@anker.com',
            CompanyContactInformation: '10900 NE 8th St, Ste 501, Bellevue WA 98004',
        },
        {
            key: '4',
            EmploymentEntity: 'Anker Innovations Deutschland GmbH; ',
            HRContactInformation: 'hr-team@anker-in.com',
            CompanyContactInformation: 'Georg-Muche-Street 3，80807 Munich',
        },
        {
            key: '5',
            EmploymentEntity: 'Anker Technology (UK) Ltd. ',
            HRContactInformation: 'hr-team@anker-in.com',
            CompanyContactInformation: 'Gnr8 49 Clarendon Road, Watford, Hertfordshire, England, WD17 1HP',
        },
    ];

    const columns = [
        {
            title: 'Employment Entity',
            dataIndex: 'EmploymentEntity',
            key: 'EmploymentEntity',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 150,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                }
            },
            render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
        },
        {
            title: 'HR Contact Information',
            dataIndex: 'HRContactInformation',
            key: 'HRContactInformation',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 150,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                }
            },
            render: (text) => <Tooltip placement="topLeft" title={text}><a>{text}</a></Tooltip>
        },
        {
            title: 'Company Contact Information',
            dataIndex: 'CompanyContactInformation',
            key: 'CompanyContactInformation',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 150,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                }
            },
            render: (text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
        },
    ];
    return (
        <div className={styles.home}>
            <Row>
                <Col span={6}>
                    <Anchor targetOffset={10} style={{ paddingTop: '10px', }}>
                        {/* <Link className={styles.anchorLink} href='#policyEn0' title='RECRUITMENT INFORMATION NOTICE' /> */}
                        <Link className={styles.anchorLink} href='#policyEn1' title='1. What personal data do we collect?' />
                        <Link className={styles.anchorLink} href='#policyEn2' title='2. how we use your Personal data' />
                        <Link className={styles.anchorLink} href='#policyEn3' title='3. How we share your personal data' />
                        <Link className={styles.anchorLink} href='#policyEn4' title='4. For which purposes and on what grounds do we process your personal data? ' />
                        <Link className={styles.anchorLink} href='#policyEn5' title='5. International transfers of your personal data' />
                        <Link className={styles.anchorLink} href='#policyEn6' title='6. How are your personal data secured? ' />
                        <Link className={styles.anchorLink} href='#policyEn7' title='7. How long are your personal data retained?' />
                        <Link className={styles.anchorLink} href='#policyEn8' title='8. How can you exercise your privacy rights?' />
                        <Link className={styles.anchorLink} href='#policyEn9' title='9. Changes to this Notice' />
                        <Link className={styles.anchorLink} href='#policyEn10' title='10. How can you contact us or lodge a complaint?' />
                    </Anchor>
                </Col>
                <Col span={18} style={{ padding: '0 20px', }}>
                    <div id="policyEn0" className={styles.policyTitle}>RECRUITMENT INFORMATION NOTICE</div>
                    <div className="policy-describe">
                        <p>Updated on June 20, 2023</p>
                        <p>Thanks for considering a job opportunity at ANKER and its subsidiaries, joint ventures and affiliates (“<span className={styles.fontMax}>we</span>” or “<span className={styles.fontMax}>Company</span>”). Your privacy is of utmost importance to us and we are committed to protecting your privacy.</p>
                        <p>This Recruitment Information Notice (“<span className={styles.fontMax}></span>Notice”) is a global notice which describes broadly  how we collect information relating to Candidates through our online recruitment system on a website on our domain, the exact website link of which may change from time to time (the “<span className={styles.fontMax}>Site</span>”) and through other means, as well as how we process and potentially use such information. The Company acknowledges that the laws of certain jurisdictions may restrict, limit, or prevent, either in whole or in part, the Company from collecting, processing, or using such information in the manner described below. Accordingly, in the event that any portion of this Notice conflicts with applicable federal, state, local, or other law(s), such law(s) shall supersede and govern. Similarly, the Company will not collect, process, or use any such information in a manner that violates any applicable federal, state, local, or other law(s). </p>
                        <p>The purpose of this Notice is to make you aware of how the Company will Process Personal Data that we obtain about Candidates through the Site and other means. Please refer to Annex 1 for details of the data controller of the Personal Data if you are based within the European Economic Area, UK and United States or the work location of job listings we publish on the Site is within the European Economic Area, UK and United States.</p>
                        <p>“<span className={styles.fontMax}>Candidate</span>” means any individual who makes an application (directly or indirectly via a representative) to join the Company through the Site.</p>
                        <p>“<span className={styles.fontMax}>Processing</span>” means any operation or set of operations which is performed on Personal Data or on sets of Personal Data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</p>
                        <p>“<span className={styles.fontMax}>Personal Data</span>” means any information relating to an identified or identifiable natural person (‘data subject’, in this Notice, namely our Candidates); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, or employment information.</p>
                        <p>We will try our best to provide you with all information in a clear and readable format. However, if you have any questions about our use of your Personal Data after reading this Notice, please contact our local HR.</p>
                    </div>
                    <div id="policyEn1" className={styles.policyTips}>
                        <div className={styles.title}>1. What personal data do we collect?</div>
                        <p>The Company processes the Personal Data of Candidates, as necessary to evaluate a Candidate’s application for employment and for our legitimate business interests relating to processing and considering a Candidate’s application to join the Company.</p>
                        <p className={styles.subTitle}>1.1 The categories of Personal Data collected by the Company through the Site and via other means include but are not limited to the following:</p>
                        <p>- Contact information: your name, email address, telephone number, home address</p>
                        <p>- Personal details: Gender, date of birth, Nationality and immigration status</p>
                        <p>- Employment and education information: previous employment history and positions held, qualifications, attendance at educational establishments and training received，past income (salary bank statements) and proof of past work performance</p>
                        <p>- Background check information，including providing contact details of previous colleagues</p>
                        <p>- Resume details, cover letters or work samples</p>
                        <p>Competency assessments</p>
                        <p className={styles.subTitle}>1.2 From Third Parties</p>
                        <p>We may obtain information about you from other sources, to the extent permitted by applicable law, in connection with your application and as part of the interview process.</p>
                        <p>We may obtain information through your contact with us from third parties such as referees, identity verification and background check service providers. You may also choose to provide us with access to certain Personal Data stored by third parties on professional social media sites, such as LinkedIn, Glassdoor. </p>
                        <p className={styles.subTitle}>1.3 Special Categories of Personal Data Collected</p>
                        <p>In general, we do not require Candidates to provide Special Categories of Personal Data, such as information about your health, race, ethnicity, religion, or sexual orientation or data relating to offences, criminal convictions or security measures, or any other data identified as such under applicable local laws. </p>
                        <p>The Company only collects Special Categories of Personal Data when permitted by law and only after establishing appropriate safeguards. Processing of the following Special Categories will only be carried out by the Company where permitted by law and in the following circumstances:</p>
                        <p>Criminal convictions - only where information relating to a Candidate’s past criminal convictions would be directly relevant to determining his or her fitness and suitability for a particular position, where relevant to potential or on-going litigation or legal proceedings that could impact the Candidate’s future employment relationship with the Company or where necessary to satisfy the Company’s obligations arising under health and safety or other applicable laws. Where the Company intends to obtain information relating to a Candidate’s commission of past criminal offences, the Candidate shall be notified beforehand;</p>
                        <p>Health information or drug screening checks - to comply with occupational health and safety laws and or regulations or when necessary to manage and administer leave or absences, or to make or consider making reasonable adjustments.</p>
                        <p>In some jurisdictions, explicit consent may be required in terms of collecting and processing of Special Categories of Personal Data. In this case, Special Categories of Personal Data shall only be processed by the Company provided that you give your consent and that the processing of Special Categories of Personal Data is precisely justified by the particularities of a position. </p>
                    </div>
                    <div id="policyEn2" className={styles.policyTips}>
                        <div className={styles.title}>2. how we use your Personal data</div>
                        <p>We may use your Personal Data in our recruitment process and human resources management purposes at a global level, including:</p>
                        <p>- Carrying out and supporting human resources functions and activities, including assessing your qualifications, skills, and interests against our career opportunities;</p>
                        <p>- Facilitating the recruiting and interview process, including communicating with you about your application</p>
                        <p>- Preparing an offer letter, if your application is successful;</p>
                        <p>- Verifying the information, you or others provide and carrying out reference checks or conducting background and other pre-employment checks to the extent permitted by applicable law;</p>
                        <p>- Complying with applicable laws, regulations, legal processes or enforceable governmental requests.</p>
                        <p>If you are offered and accept employment with us, the information collected during the application and recruitment process will become part of your employment record.</p>
                        <p>By Submitting your application, you consent to enrol in our talent lake. We may retain your application and Personal Data for future job opportunities that match your skills and qualifications for 5 years from the date of your application. You have the right to withdraw your consent at any time by contacting our HR at [hr-recruit@anker-in.com]. </p>
                    </div>
                    <div id="policyEn3" className={styles.policyTips}>
                        <div className={styles.title}>3. How we share your personal data</div>
                        <p>- The Personal Data obtained through the Site and via other means may be shared across the Company and our subsidiaries and affiliated companies, where necessary for legitimate business purposes relating to the recruitment of the Candidate or to comply with applicable legal requirements.</p>
                        <p>- The Company may also share Personal Data with third party service providers (e.g.  background check providers) that it has retained to perform services on its behalf or for legal/regulatory purposes. Where Personal Data are disclosed to third parties, appropriate safeguards shall be implemented to ensure that the data will be Processed legitimately and protected appropriately.</p>
                        <p>- Compliance with laws or any competent law enforcement body, regulatory body, government agency, court or third party: We may disclose information where we believe disclosure is necessary or required by law or regulation, to comply with legal process or government requests (including in response to public authorities to meet equal opportunity laws, national security or law enforcement requirements); or to exercise, establish or defend our legal rights.</p>
                        <p>- We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, acquisition, dissolution, corporate reorganization or similar event. We will inform any buyer that your information shall only be used in accordance with this Notice.</p>
                        <p>- Any other person with your consent to the disclosure.</p>
                    </div>
                    <div id="policyEn4" className={styles.policyTips}>
                        <div className={styles.title}>4. For which purposes and on what grounds do we process your personal data? </div>
                        <p>Our legal basis for processing your Personal Data, in the ways described in this Notice, include:</p>
                        <p>- As necessary to prepare and enter into an employment contract with you;</p>
                        <p>- Consistent with specific consents, which you may revoke at any time;</p>
                        <p>- As necessary to comply with our legal obligations;</p>
                        <p>- To protect your vital interests or those of others;</p>
                        <p>- As necessary for our (or others’) legitimate interests, including our interests in considering candidates for current and future employment opportunities, and in managing and improving our recruitment and hiring processes, unless those interests are overridden by your interests or fundamental rights and freedoms, which require protection of Personal Data.</p>
                    </div>
                    <div id="policyEn5" className={styles.policyTips}>
                        <div className={styles.title}>5. International transfers of your personal data</div>
                        <p>As a global organization data, we collect may be transferred internationally throughout our worldwide organization. By providing Personal Data for any of the purposes of recruitment, you acknowledge that your Personal Data may be transferred to or stored in China. </p>
                        <p>If you are a resident of the European Economic Area (EEA) or UK, we will protect your Personal Data when it is transferred outside of the EEA or UK by:</p>
                        <p>- Processing it in a territory which the European Commission has determined provides an adequate level of protection for personal information; or </p>
                        <p>- Otherwise implementing appropriate safeguards to protect your Personal Data, including implementing the European Commission’s SCCs or UK’s SCCs.</p>
                        <p>If you require further information about our international transfers of personal information, , please contact us by using the contact details at the bottom of this Notice.</p>
                    </div>
                    <div id="policyEn6" className={styles.policyTips}>
                        <div className={styles.title}>6. How are your personal data secured? </div>
                        <p>We have has taken adequate safeguards to ensure the confidentiality and security of your Personal Data. We have implemented appropriate technical, physical and organizational measures to protect Personal Data against accidental or unlawful destruction or accidental loss, damage, alteration, unauthorised disclosure or access as well as all other forms of unlawful processing (including, but not limited to, unnecessary collection) or further processing.</p>
                    </div>
                    <div id="policyEn7" className={styles.policyTips}>
                        <div className={styles.title}>7. How long are your personal data retained?</div>
                        <p>We'll retain your Personal Data for as long as required to comply with our legal obligations, resolve disputes, and to enforce our contractual agreements, or as necessary for our legitimate interests, such as to consider you for other current and future employment opportunities in the Company. </p>
                        <p>If you're successful in your application for a position in the Company, we retain the information that you provide during the application process, and information about your application process, as part of your employee records.</p>
                        <p>If you do not want us to retain your information for consideration for other roles, or want us to update it, please contact us by using the contact details at the bottom of this Notice. Please note, however, that we may retain some information if required by law or as necessary to protect ourselves from legal claims.</p>
                    </div>
                    <div id="policyEn8" className={styles.policyTips}>
                        <div className={styles.title}>8. How can you exercise your privacy rights?</div>
                        <p>If you wish to exercise your rights under applicable local laws with regards the processing of your Personal Data via the Site and other means, you must do so in writing and direct the request to our local HR contact. We may ask you for additional information to confirm your identity and for security purposes, before disclosing information requested to you.</p>
                        <p>You may have the right to request access to your Personal Data, to have it rectified or erased, to object to its processing or to have access to it restricted. You may also have the right to receive a copy of your Personal Data in a machine-readable format, the right to not to be subject to any decision that significantly affects you being taken solely by automated processing, including profiling. The types of rights you enjoy may vary depending on the jurisdiction in which you employer is located. </p>
                        <p>If you have given your consent to a certain purpose, you can withdraw your consent at any time. Please keep in mind that withdrawal does not have retroactive effect. </p>
                    </div>
                    <div id="policyEn9" className={styles.policyTips}>
                        <div className={styles.title}>9. Changes to this Notice</div>
                        <p>We will review and update this Notice periodically in response to changing legal, technical, and business developments. When we update this Notice, we will note the date of its most recent revision above. If we make material changes to this Notice, we may take appropriate measures to inform you in a manner that is consistent with the significance of the changes we make. </p>
                    </div>
                    <div id="policyEn10" className={styles.policyTips}>
                        <div className={styles.title}>10. How can you contact us or lodge a complaint?</div>
                        <p>If you have any questions about the way we process your Personal Data, please read this Notice first. For additional questions please contact us our local HR. Please note that we take your satisfaction very seriously. Should you have a complaint, please also direct it to the same email address and we will respond to you as soon as we can. </p>
                        <p>If we are unable to resolve your concerns, you can of course also lodge a complaint with your local data privacy supervisory authority. </p>
                    </div>
                    <Divider />
                    <div className={styles.tableTitle}>Annex 1</div>
                    <p>The following companies are controllers of Personal Data within the European Economic Area, UK or United States:</p>
                    <Table rowClassName={styles.tableRow} className={styles.table} bordered dataSource={dataSource} columns={columns} pagination={false} />
                </Col>
            </Row>
        </div>
    );
};

export default EnglishPolicy;
